import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66e41e5c = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _0887d080 = () => interopDefault(import('../pages/caramaps_host.vue' /* webpackChunkName: "pages/caramaps_host" */))
const _53ef81ba = () => interopDefault(import('../pages/activities/index.vue' /* webpackChunkName: "pages/activities/index" */))
const _49ff2526 = () => interopDefault(import('../pages/caras/ambassador.vue' /* webpackChunkName: "pages/caras/ambassador" */))
const _8a2c9fb0 = () => interopDefault(import('../pages/mediakit/index.vue' /* webpackChunkName: "pages/mediakit/index" */))
const _1258498d = () => interopDefault(import('../pages/directory/index.vue' /* webpackChunkName: "pages/directory/index" */))
const _4ff522ad = () => interopDefault(import('../pages/application.vue' /* webpackChunkName: "pages/application" */))
const _ded117ba = () => interopDefault(import('../pages/application-news.vue' /* webpackChunkName: "pages/application-news" */))
const _01f4bf69 = () => interopDefault(import('../pages/awards.vue' /* webpackChunkName: "pages/awards" */))
const _419971b9 = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _51112f98 = () => interopDefault(import('../pages/map/_slug.vue' /* webpackChunkName: "pages/map/_slug" */))
const _e8f4d4de = () => interopDefault(import('../pages/caras/leaderboard.vue' /* webpackChunkName: "pages/caras/leaderboard" */))
const _24a661e7 = () => interopDefault(import('../pages/comments.vue' /* webpackChunkName: "pages/comments" */))
const _39d5dff4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _196d6cef = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3a5ef95c = () => interopDefault(import('../pages/discover.vue' /* webpackChunkName: "pages/discover" */))
const _60709974 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _57dc7b86 = () => interopDefault(import('../pages/password/generate.vue' /* webpackChunkName: "pages/password/generate" */))
const _e33679d4 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _48ee0a02 = () => interopDefault(import('../pages/places.vue' /* webpackChunkName: "pages/places" */))
const _30851220 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _cb313366 = () => interopDefault(import('../pages/member.vue' /* webpackChunkName: "pages/member" */))
const _0df0b08d = () => interopDefault(import('../pages/legals.vue' /* webpackChunkName: "pages/legals" */))
const _793480e2 = () => interopDefault(import('../pages/mytrips/index.vue' /* webpackChunkName: "pages/mytrips/index" */))
const _4eada6e4 = () => interopDefault(import('../pages/password/request.vue' /* webpackChunkName: "pages/password/request" */))
const _67639928 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _14925f68 = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _733982b0 = () => interopDefault(import('../pages/pictures.vue' /* webpackChunkName: "pages/pictures" */))
const _ecf38a98 = () => interopDefault(import('../pages/premium.vue' /* webpackChunkName: "pages/premium" */))
const _1354c7e0 = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _057f22ec = () => interopDefault(import('../pages/pro.vue' /* webpackChunkName: "pages/pro" */))
const _28f38e6e = () => interopDefault(import('../pages/pro/_slug/activity.vue' /* webpackChunkName: "pages/pro/_slug/activity" */))
const _98804000 = () => interopDefault(import('../pages/pro/_slug/poi.vue' /* webpackChunkName: "pages/pro/_slug/poi" */))
const _85340a66 = () => interopDefault(import('../pages/substitute.vue' /* webpackChunkName: "pages/substitute" */))
const _43e95e18 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _301464ee = () => interopDefault(import('../pages/teamlist.vue' /* webpackChunkName: "pages/teamlist" */))
const _b5a3a1f6 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _ea05d178 = () => interopDefault(import('../pages/caras/trombinoscope.vue' /* webpackChunkName: "pages/caras/trombinoscope" */))
const _d18fc6da = () => interopDefault(import('../pages/trips/home.vue' /* webpackChunkName: "pages/trips/home" */))
const _2b6b40ed = () => interopDefault(import('../pages/activities/map.vue' /* webpackChunkName: "pages/activities/map" */))
const _49360068 = () => interopDefault(import('../pages/activities/map/_slug.vue' /* webpackChunkName: "pages/activities/map/_slug" */))
const _67878331 = () => interopDefault(import('../pages/activities/new.vue' /* webpackChunkName: "pages/activities/new" */))
const _7a1d6290 = () => interopDefault(import('../pages/poi/new.vue' /* webpackChunkName: "pages/poi/new" */))
const _cee24614 = () => interopDefault(import('../pages/mediakit/contact.vue' /* webpackChunkName: "pages/mediakit/contact" */))
const _6edd1701 = () => interopDefault(import('../pages/trips/themes/index.vue' /* webpackChunkName: "pages/trips/themes/index" */))
const _6f39c151 = () => interopDefault(import('../pages/trips/top.vue' /* webpackChunkName: "pages/trips/top" */))
const _db8808e8 = () => interopDefault(import('../pages/trips/themes/_category.vue' /* webpackChunkName: "pages/trips/themes/_category" */))
const _d9112c0a = () => interopDefault(import('../pages/trips/themes/_country/index.vue' /* webpackChunkName: "pages/trips/themes/_country/index" */))
const _6709e8ee = () => interopDefault(import('../pages/trips/themes/_country/_category/index.vue' /* webpackChunkName: "pages/trips/themes/_country/_category/index" */))
const _da6174f4 = () => interopDefault(import('../pages/mytrips/_uuid/detail.vue' /* webpackChunkName: "pages/mytrips/_uuid/detail" */))
const _59ada6ba = () => interopDefault(import('../pages/partner/_slug/index.vue' /* webpackChunkName: "pages/partner/_slug/index" */))
const _3fc3ec94 = () => interopDefault(import('../pages/profile/_uuid/index.vue' /* webpackChunkName: "pages/profile/_uuid/index" */))
const _0cd97f0e = () => interopDefault(import('../pages/trips/details/_slug.vue' /* webpackChunkName: "pages/trips/details/_slug" */))
const _dd359e6a = () => interopDefault(import('../pages/password/reset/_token.vue' /* webpackChunkName: "pages/password/reset/_token" */))
const _d8fa6814 = () => interopDefault(import('../pages/send/_slug.vue' /* webpackChunkName: "pages/send/_slug" */))
const _cac35622 = () => interopDefault(import('../pages/directory/_country/index.vue' /* webpackChunkName: "pages/directory/_country/index" */))
const _17e13c5e = () => interopDefault(import('../pages/activities/_uuid/edit.vue' /* webpackChunkName: "pages/activities/_uuid/edit" */))
const _145af99e = () => interopDefault(import('../pages/activities/_uuid/details.vue' /* webpackChunkName: "pages/activities/_uuid/details" */))
const _35790e89 = () => interopDefault(import('../pages/activities/_uuid/pro.vue' /* webpackChunkName: "pages/activities/_uuid/pro" */))
const _1aabbaf7 = () => interopDefault(import('../pages/poi/_uuid/edit.vue' /* webpackChunkName: "pages/poi/_uuid/edit" */))
const _009baa6f = () => interopDefault(import('../pages/dashboard/_uuid/details.vue' /* webpackChunkName: "pages/dashboard/_uuid/details" */))
const _2cfc9998 = () => interopDefault(import('../pages/poi/_uuid/lock.vue' /* webpackChunkName: "pages/poi/_uuid/lock" */))
const _39be5438 = () => interopDefault(import('../pages/profile/_uuid/edit.vue' /* webpackChunkName: "pages/profile/_uuid/edit" */))
const _1002344e = () => interopDefault(import('../pages/profile/_uuid/delete.vue' /* webpackChunkName: "pages/profile/_uuid/delete" */))
const _55cc59d1 = () => interopDefault(import('../pages/directory/_country/_department/index.vue' /* webpackChunkName: "pages/directory/_country/_department/index" */))
const _879d1bca = () => interopDefault(import('../pages/directory/_country/_department/_type/index.vue' /* webpackChunkName: "pages/directory/_country/_department/_type/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accueil",
    component: _66e41e5c,
    name: "home___fr"
  }, {
    path: "/accueillant",
    component: _0887d080,
    name: "caramaps_host___fr"
  }, {
    path: "/activites",
    component: _53ef81ba,
    name: "activities___fr"
  }, {
    path: "/ambassadeur",
    component: _49ff2526,
    name: "caras-ambassador___fr"
  }, {
    path: "/annonceurs",
    component: _8a2c9fb0,
    name: "mediakit___fr"
  }, {
    path: "/annuaire",
    component: _1258498d,
    name: "directory___fr"
  }, {
    path: "/application",
    component: _4ff522ad,
    name: "application___fr"
  }, {
    path: "/application-news",
    component: _ded117ba,
    name: "application-news___fr"
  }, {
    path: "/awards",
    component: _01f4bf69,
    name: "awards___fr"
  }, {
    path: "/carte",
    component: _419971b9,
    name: "map___fr",
    children: [{
      path: "/carte/:slug",
      component: _51112f98,
      name: "map-slug___fr"
    }]
  }, {
    path: "/classement",
    component: _e8f4d4de,
    name: "caras-leaderboard___fr"
  }, {
    path: "/commentaires",
    component: _24a661e7,
    name: "comments___fr"
  }, {
    path: "/connexion",
    component: _39d5dff4,
    name: "login___fr"
  }, {
    path: "/de",
    component: _196d6cef,
    name: "index___de"
  }, {
    path: "/decouvrez",
    component: _3a5ef95c,
    name: "discover___fr"
  }, {
    path: "/en",
    component: _196d6cef,
    name: "index___en"
  }, {
    path: "/es",
    component: _196d6cef,
    name: "index___es"
  }, {
    path: "/favoris",
    component: _60709974,
    name: "favorites___fr"
  }, {
    path: "/generer-nouveau-mot-de-passe",
    component: _57dc7b86,
    name: "password-generate___fr"
  }, {
    path: "/inscription",
    component: _e33679d4,
    name: "register___fr"
  }, {
    path: "/it",
    component: _196d6cef,
    name: "index___it"
  }, {
    path: "/lieux",
    component: _48ee0a02,
    name: "places___fr"
  }, {
    path: "/maintenance",
    component: _30851220,
    name: "maintenance___fr"
  }, {
    path: "/membre",
    component: _cb313366,
    name: "member___fr"
  }, {
    path: "/mentions-legales",
    component: _0df0b08d,
    name: "legals___fr"
  }, {
    path: "/mes-voyages",
    component: _793480e2,
    name: "mytrips___fr"
  }, {
    path: "/mot-de-passe-oublie",
    component: _4eada6e4,
    name: "password-request___fr"
  }, {
    path: "/newsletter",
    component: _67639928,
    name: "newsletter___fr"
  }, {
    path: "/nl",
    component: _196d6cef,
    name: "index___nl"
  }, {
    path: "/partenaires",
    component: _14925f68,
    name: "partner___fr"
  }, {
    path: "/photos",
    component: _733982b0,
    name: "pictures___fr"
  }, {
    path: "/premium",
    component: _ecf38a98,
    name: "premium___fr"
  }, {
    path: "/presse",
    component: _1354c7e0,
    name: "press___fr"
  }, {
    path: "/pro",
    component: _057f22ec,
    name: "pro___fr",
    children: [{
      path: "/pro/:slug/activity",
      component: _28f38e6e,
      name: "pro-slug-activity___fr"
    }, {
      path: "/pro/:slug/poi",
      component: _98804000,
      name: "pro-slug-poi___fr"
    }]
  }, {
    path: "/pt",
    component: _196d6cef,
    name: "index___pt"
  }, {
    path: "/substitute",
    component: _85340a66,
    name: "substitute___fr"
  }, {
    path: "/tableau-de-bord",
    component: _43e95e18,
    name: "dashboard___fr"
  }, {
    path: "/teamlist",
    component: _301464ee,
    name: "teamlist___fr"
  }, {
    path: "/test",
    component: _b5a3a1f6,
    name: "test___fr"
  }, {
    path: "/trombinoscope",
    component: _ea05d178,
    name: "caras-trombinoscope___fr"
  }, {
    path: "/voyages",
    component: _d18fc6da,
    name: "trips-home___fr"
  }, {
    path: "/activites/carte",
    component: _2b6b40ed,
    name: "activities-map___fr",
    children: [{
      path: "/activites/carte/:slug",
      component: _49360068,
      name: "activities-map-slug___fr"
    }]
  }, {
    path: "/activites/nouveau",
    component: _67878331,
    name: "activities-new___fr"
  }, {
    path: "/aire/ajouter",
    component: _7a1d6290,
    name: "poi-new___fr"
  }, {
    path: "/annonceurs/kit-media",
    component: _cee24614,
    name: "mediakit-contact___fr"
  }, {
    path: "/de/aktivitaeten",
    component: _53ef81ba,
    name: "activities___de"
  }, {
    path: "/de/application",
    component: _4ff522ad,
    name: "application___de"
  }, {
    path: "/de/application-news",
    component: _ded117ba,
    name: "application-news___de"
  }, {
    path: "/de/armaturenbrett",
    component: _43e95e18,
    name: "dashboard___de"
  }, {
    path: "/de/awards",
    component: _01f4bf69,
    name: "awards___de"
  }, {
    path: "/de/bilder",
    component: _733982b0,
    name: "pictures___de"
  }, {
    path: "/de/botschafter",
    component: _49ff2526,
    name: "caras-ambassador___de"
  }, {
    path: "/de/entdecken",
    component: _3a5ef95c,
    name: "discover___de"
  }, {
    path: "/de/favorites",
    component: _60709974,
    name: "favorites___de"
  }, {
    path: "/de/gastgeber",
    component: _0887d080,
    name: "caramaps_host___de"
  }, {
    path: "/de/impressum",
    component: _0df0b08d,
    name: "legals___de"
  }, {
    path: "/de/kommentare",
    component: _24a661e7,
    name: "comments___de"
  }, {
    path: "/de/landkarte",
    component: _419971b9,
    name: "map___de",
    children: [{
      path: "/de/landkarte/:slug",
      component: _51112f98,
      name: "map-slug___de"
    }]
  }, {
    path: "/de/login",
    component: _39d5dff4,
    name: "login___de"
  }, {
    path: "/de/maintenance",
    component: _30851220,
    name: "maintenance___de"
  }, {
    path: "/de/meine-reise",
    component: _793480e2,
    name: "mytrips___de"
  }, {
    path: "/de/mitglied",
    component: _cb313366,
    name: "member___de"
  }, {
    path: "/de/neues-passwort-generieren",
    component: _57dc7b86,
    name: "password-generate___de"
  }, {
    path: "/de/newsletter",
    component: _67639928,
    name: "newsletter___de"
  }, {
    path: "/de/orte",
    component: _48ee0a02,
    name: "places___de"
  }, {
    path: "/de/partners",
    component: _14925f68,
    name: "partner___de"
  }, {
    path: "/de/passwort-vergessen",
    component: _4eada6e4,
    name: "password-request___de"
  }, {
    path: "/de/pramie",
    component: _ecf38a98,
    name: "premium___de"
  }, {
    path: "/de/presse",
    component: _1354c7e0,
    name: "press___de"
  }, {
    path: "/de/pro",
    component: _057f22ec,
    name: "pro___de",
    children: [{
      path: "/de/pro/:slug/activity",
      component: _28f38e6e,
      name: "pro-slug-activity___de"
    }, {
      path: "/de/pro/:slug/poi",
      component: _98804000,
      name: "pro-slug-poi___de"
    }]
  }, {
    path: "/de/ranking",
    component: _e8f4d4de,
    name: "caras-leaderboard___de"
  }, {
    path: "/de/registrieren",
    component: _e33679d4,
    name: "register___de"
  }, {
    path: "/de/reise",
    component: _d18fc6da,
    name: "trips-home___de"
  }, {
    path: "/de/substitute",
    component: _85340a66,
    name: "substitute___de"
  }, {
    path: "/de/teamlist",
    component: _301464ee,
    name: "teamlist___de"
  }, {
    path: "/de/test",
    component: _b5a3a1f6,
    name: "test___de"
  }, {
    path: "/de/trombinoskop",
    component: _ea05d178,
    name: "caras-trombinoscope___de"
  }, {
    path: "/de/verzeichnis",
    component: _1258498d,
    name: "directory___de"
  }, {
    path: "/de/werbetreibende",
    component: _8a2c9fb0,
    name: "mediakit___de"
  }, {
    path: "/de/willkommen",
    component: _66e41e5c,
    name: "home___de"
  }, {
    path: "/en/activities",
    component: _53ef81ba,
    name: "activities___en"
  }, {
    path: "/en/ambassador",
    component: _49ff2526,
    name: "caras-ambassador___en"
  }, {
    path: "/en/application",
    component: _4ff522ad,
    name: "application___en"
  }, {
    path: "/en/application-news",
    component: _ded117ba,
    name: "application-news___en"
  }, {
    path: "/en/awards",
    component: _01f4bf69,
    name: "awards___en"
  }, {
    path: "/en/comments",
    component: _24a661e7,
    name: "comments___en"
  }, {
    path: "/en/dashboard",
    component: _43e95e18,
    name: "dashboard___en"
  }, {
    path: "/en/directory",
    component: _1258498d,
    name: "directory___en"
  }, {
    path: "/en/discover",
    component: _3a5ef95c,
    name: "discover___en"
  }, {
    path: "/en/favorites",
    component: _60709974,
    name: "favorites___en"
  }, {
    path: "/en/forgot-password",
    component: _4eada6e4,
    name: "password-request___en"
  }, {
    path: "/en/generate-new-password",
    component: _57dc7b86,
    name: "password-generate___en"
  }, {
    path: "/en/home",
    component: _66e41e5c,
    name: "home___en"
  }, {
    path: "/en/host",
    component: _0887d080,
    name: "caramaps_host___en"
  }, {
    path: "/en/legals",
    component: _0df0b08d,
    name: "legals___en"
  }, {
    path: "/en/login",
    component: _39d5dff4,
    name: "login___en"
  }, {
    path: "/en/maintenance",
    component: _30851220,
    name: "maintenance___en"
  }, {
    path: "/en/map",
    component: _419971b9,
    name: "map___en",
    children: [{
      path: "/en/map/:slug",
      component: _51112f98,
      name: "map-slug___en"
    }]
  }, {
    path: "/en/mediakit",
    component: _8a2c9fb0,
    name: "mediakit___en"
  }, {
    path: "/en/member",
    component: _cb313366,
    name: "member___en"
  }, {
    path: "/en/my-trips",
    component: _793480e2,
    name: "mytrips___en"
  }, {
    path: "/en/newsletter",
    component: _67639928,
    name: "newsletter___en"
  }, {
    path: "/en/partners",
    component: _14925f68,
    name: "partner___en"
  }, {
    path: "/en/pictures",
    component: _733982b0,
    name: "pictures___en"
  }, {
    path: "/en/places",
    component: _48ee0a02,
    name: "places___en"
  }, {
    path: "/en/premium",
    component: _ecf38a98,
    name: "premium___en"
  }, {
    path: "/en/press",
    component: _1354c7e0,
    name: "press___en"
  }, {
    path: "/en/pro",
    component: _057f22ec,
    name: "pro___en",
    children: [{
      path: "/en/pro/:slug/activity",
      component: _28f38e6e,
      name: "pro-slug-activity___en"
    }, {
      path: "/en/pro/:slug/poi",
      component: _98804000,
      name: "pro-slug-poi___en"
    }]
  }, {
    path: "/en/ranking",
    component: _e8f4d4de,
    name: "caras-leaderboard___en"
  }, {
    path: "/en/register",
    component: _e33679d4,
    name: "register___en"
  }, {
    path: "/en/substitute",
    component: _85340a66,
    name: "substitute___en"
  }, {
    path: "/en/teamlist",
    component: _301464ee,
    name: "teamlist___en"
  }, {
    path: "/en/test",
    component: _b5a3a1f6,
    name: "test___en"
  }, {
    path: "/en/trips",
    component: _d18fc6da,
    name: "trips-home___en"
  }, {
    path: "/en/trombinoscope",
    component: _ea05d178,
    name: "caras-trombinoscope___en"
  }, {
    path: "/es/actividades",
    component: _53ef81ba,
    name: "activities___es"
  }, {
    path: "/es/anfitrion",
    component: _0887d080,
    name: "caramaps_host___es"
  }, {
    path: "/es/anunciantes",
    component: _8a2c9fb0,
    name: "mediakit___es"
  }, {
    path: "/es/application",
    component: _4ff522ad,
    name: "application___es"
  }, {
    path: "/es/application-news",
    component: _ded117ba,
    name: "application-news___es"
  }, {
    path: "/es/apuro",
    component: _1354c7e0,
    name: "press___es"
  }, {
    path: "/es/awards",
    component: _01f4bf69,
    name: "awards___es"
  }, {
    path: "/es/clasificacion",
    component: _e8f4d4de,
    name: "caras-leaderboard___es"
  }, {
    path: "/es/comentarios",
    component: _24a661e7,
    name: "comments___es"
  }, {
    path: "/es/contrasena-olvidada",
    component: _4eada6e4,
    name: "password-request___es"
  }, {
    path: "/es/descubrir",
    component: _3a5ef95c,
    name: "discover___es"
  }, {
    path: "/es/directorio",
    component: _1258498d,
    name: "directory___es"
  }, {
    path: "/es/embajador",
    component: _49ff2526,
    name: "caras-ambassador___es"
  }, {
    path: "/es/favorites",
    component: _60709974,
    name: "favorites___es"
  }, {
    path: "/es/generar-nueva-contrasena",
    component: _57dc7b86,
    name: "password-generate___es"
  }, {
    path: "/es/home",
    component: _66e41e5c,
    name: "home___es"
  }, {
    path: "/es/imagen",
    component: _733982b0,
    name: "pictures___es"
  }, {
    path: "/es/login",
    component: _39d5dff4,
    name: "login___es"
  }, {
    path: "/es/lugares",
    component: _48ee0a02,
    name: "places___es"
  }, {
    path: "/es/maintenance",
    component: _30851220,
    name: "maintenance___es"
  }, {
    path: "/es/mapa",
    component: _419971b9,
    name: "map___es",
    children: [{
      path: "/es/mapa/:slug",
      component: _51112f98,
      name: "map-slug___es"
    }]
  }, {
    path: "/es/miembro",
    component: _cb313366,
    name: "member___es"
  }, {
    path: "/es/mis-viajes",
    component: _793480e2,
    name: "mytrips___es"
  }, {
    path: "/es/newsletter",
    component: _67639928,
    name: "newsletter___es"
  }, {
    path: "/es/notas-legales",
    component: _0df0b08d,
    name: "legals___es"
  }, {
    path: "/es/premium",
    component: _ecf38a98,
    name: "premium___es"
  }, {
    path: "/es/pro",
    component: _057f22ec,
    name: "pro___es",
    children: [{
      path: "/es/pro/:slug/activity",
      component: _28f38e6e,
      name: "pro-slug-activity___es"
    }, {
      path: "/es/pro/:slug/poi",
      component: _98804000,
      name: "pro-slug-poi___es"
    }]
  }, {
    path: "/es/registro",
    component: _e33679d4,
    name: "register___es"
  }, {
    path: "/es/socios",
    component: _14925f68,
    name: "partner___es"
  }, {
    path: "/es/substitute",
    component: _85340a66,
    name: "substitute___es"
  }, {
    path: "/es/tablero-de-control",
    component: _43e95e18,
    name: "dashboard___es"
  }, {
    path: "/es/teamlist",
    component: _301464ee,
    name: "teamlist___es"
  }, {
    path: "/es/test",
    component: _b5a3a1f6,
    name: "test___es"
  }, {
    path: "/es/trombinoscopio",
    component: _ea05d178,
    name: "caras-trombinoscope___es"
  }, {
    path: "/es/viajes",
    component: _d18fc6da,
    name: "trips-home___es"
  }, {
    path: "/it/ambasciatore",
    component: _49ff2526,
    name: "caras-ambassador___it"
  }, {
    path: "/it/application",
    component: _4ff522ad,
    name: "application___it"
  }, {
    path: "/it/application-news",
    component: _ded117ba,
    name: "application-news___it"
  }, {
    path: "/it/attivita",
    component: _53ef81ba,
    name: "activities___it"
  }, {
    path: "/it/awards",
    component: _01f4bf69,
    name: "awards___it"
  }, {
    path: "/it/commenti",
    component: _24a661e7,
    name: "comments___it"
  }, {
    path: "/it/cruscotto",
    component: _43e95e18,
    name: "dashboard___it"
  }, {
    path: "/it/elenco",
    component: _1258498d,
    name: "directory___it"
  }, {
    path: "/it/favorites",
    component: _60709974,
    name: "favorites___it"
  }, {
    path: "/it/fretta",
    component: _1354c7e0,
    name: "press___it"
  }, {
    path: "/it/generare-nuova-password",
    component: _57dc7b86,
    name: "password-generate___it"
  }, {
    path: "/it/home",
    component: _66e41e5c,
    name: "home___it"
  }, {
    path: "/it/i-miei-viaggi",
    component: _793480e2,
    name: "mytrips___it"
  }, {
    path: "/it/immagini",
    component: _733982b0,
    name: "pictures___it"
  }, {
    path: "/it/inserzionisti",
    component: _8a2c9fb0,
    name: "mediakit___it"
  }, {
    path: "/it/login",
    component: _39d5dff4,
    name: "login___it"
  }, {
    path: "/it/luoghi",
    component: _48ee0a02,
    name: "places___it"
  }, {
    path: "/it/maintenance",
    component: _30851220,
    name: "maintenance___it"
  }, {
    path: "/it/mappa",
    component: _419971b9,
    name: "map___it",
    children: [{
      path: "/it/mappa/:slug",
      component: _51112f98,
      name: "map-slug___it"
    }]
  }, {
    path: "/it/membro",
    component: _cb313366,
    name: "member___it"
  }, {
    path: "/it/menzioni-legali",
    component: _0df0b08d,
    name: "legals___it"
  }, {
    path: "/it/newsletter",
    component: _67639928,
    name: "newsletter___it"
  }, {
    path: "/it/ospite",
    component: _0887d080,
    name: "caramaps_host___it"
  }, {
    path: "/it/partner",
    component: _14925f68,
    name: "partner___it"
  }, {
    path: "/it/password-dimenticata",
    component: _4eada6e4,
    name: "password-request___it"
  }, {
    path: "/it/premio",
    component: _ecf38a98,
    name: "premium___it"
  }, {
    path: "/it/pro",
    component: _057f22ec,
    name: "pro___it",
    children: [{
      path: "/it/pro/:slug/activity",
      component: _28f38e6e,
      name: "pro-slug-activity___it"
    }, {
      path: "/it/pro/:slug/poi",
      component: _98804000,
      name: "pro-slug-poi___it"
    }]
  }, {
    path: "/it/ranking",
    component: _e8f4d4de,
    name: "caras-leaderboard___it"
  }, {
    path: "/it/registrare",
    component: _e33679d4,
    name: "register___it"
  }, {
    path: "/it/scoprire",
    component: _3a5ef95c,
    name: "discover___it"
  }, {
    path: "/it/substitute",
    component: _85340a66,
    name: "substitute___it"
  }, {
    path: "/it/teamlist",
    component: _301464ee,
    name: "teamlist___it"
  }, {
    path: "/it/test",
    component: _b5a3a1f6,
    name: "test___it"
  }, {
    path: "/it/trombinoscopio",
    component: _ea05d178,
    name: "caras-trombinoscope___it"
  }, {
    path: "/it/viaggi",
    component: _d18fc6da,
    name: "trips-home___it"
  }, {
    path: "/nl/aanmelden",
    component: _e33679d4,
    name: "register___nl"
  }, {
    path: "/nl/activiteiten",
    component: _53ef81ba,
    name: "activities___nl"
  }, {
    path: "/nl/advertenties",
    component: _8a2c9fb0,
    name: "mediakit___nl"
  }, {
    path: "/nl/afbeeldingen",
    component: _733982b0,
    name: "pictures___nl"
  }, {
    path: "/nl/ambassadeur",
    component: _49ff2526,
    name: "caras-ambassador___nl"
  }, {
    path: "/nl/application",
    component: _4ff522ad,
    name: "application___nl"
  }, {
    path: "/nl/application-news",
    component: _ded117ba,
    name: "application-news___nl"
  }, {
    path: "/nl/awards",
    component: _01f4bf69,
    name: "awards___nl"
  }, {
    path: "/nl/dashboard",
    component: _43e95e18,
    name: "dashboard___nl"
  }, {
    path: "/nl/directory",
    component: _1258498d,
    name: "directory___nl"
  }, {
    path: "/nl/favorites",
    component: _60709974,
    name: "favorites___nl"
  }, {
    path: "/nl/gast",
    component: _0887d080,
    name: "caramaps_host___nl"
  }, {
    path: "/nl/haast-je",
    component: _1354c7e0,
    name: "press___nl"
  }, {
    path: "/nl/home",
    component: _66e41e5c,
    name: "home___nl"
  }, {
    path: "/nl/kaart",
    component: _419971b9,
    name: "map___nl",
    children: [{
      path: "/nl/kaart/:slug",
      component: _51112f98,
      name: "map-slug___nl"
    }]
  }, {
    path: "/nl/lid",
    component: _cb313366,
    name: "member___nl"
  }, {
    path: "/nl/login",
    component: _39d5dff4,
    name: "login___nl"
  }, {
    path: "/nl/maintenance",
    component: _30851220,
    name: "maintenance___nl"
  }, {
    path: "/nl/mijn-reizen",
    component: _793480e2,
    name: "mytrips___nl"
  }, {
    path: "/nl/newsletter",
    component: _67639928,
    name: "newsletter___nl"
  }, {
    path: "/nl/nieuw-wachtwoord-genereren",
    component: _57dc7b86,
    name: "password-generate___nl"
  }, {
    path: "/nl/ontvang",
    component: _3a5ef95c,
    name: "discover___nl"
  }, {
    path: "/nl/opmerkingen",
    component: _24a661e7,
    name: "comments___nl"
  }, {
    path: "/nl/partners",
    component: _14925f68,
    name: "partner___nl"
  }, {
    path: "/nl/plaatsen",
    component: _48ee0a02,
    name: "places___nl"
  }, {
    path: "/nl/premium",
    component: _ecf38a98,
    name: "premium___nl"
  }, {
    path: "/nl/pro",
    component: _057f22ec,
    name: "pro___nl",
    children: [{
      path: "/nl/pro/:slug/activity",
      component: _28f38e6e,
      name: "pro-slug-activity___nl"
    }, {
      path: "/nl/pro/:slug/poi",
      component: _98804000,
      name: "pro-slug-poi___nl"
    }]
  }, {
    path: "/nl/ranking",
    component: _e8f4d4de,
    name: "caras-leaderboard___nl"
  }, {
    path: "/nl/reizen",
    component: _d18fc6da,
    name: "trips-home___nl"
  }, {
    path: "/nl/substitute",
    component: _85340a66,
    name: "substitute___nl"
  }, {
    path: "/nl/teamlist",
    component: _301464ee,
    name: "teamlist___nl"
  }, {
    path: "/nl/test",
    component: _b5a3a1f6,
    name: "test___nl"
  }, {
    path: "/nl/trombinoscoop",
    component: _ea05d178,
    name: "caras-trombinoscope___nl"
  }, {
    path: "/nl/wachtwoord-vergeten",
    component: _4eada6e4,
    name: "password-request___nl"
  }, {
    path: "/nl/wettelijke",
    component: _0df0b08d,
    name: "legals___nl"
  }, {
    path: "/pt/anfitriao",
    component: _0887d080,
    name: "caramaps_host___pt"
  }, {
    path: "/pt/anunciantes",
    component: _8a2c9fb0,
    name: "mediakit___pt"
  }, {
    path: "/pt/application",
    component: _4ff522ad,
    name: "application___pt"
  }, {
    path: "/pt/application-news",
    component: _ded117ba,
    name: "application-news___pt"
  }, {
    path: "/pt/as-minhas-viagens",
    component: _793480e2,
    name: "mytrips___pt"
  }, {
    path: "/pt/atividades",
    component: _53ef81ba,
    name: "activities___pt"
  }, {
    path: "/pt/awards",
    component: _01f4bf69,
    name: "awards___pt"
  }, {
    path: "/pt/classificacao",
    component: _e8f4d4de,
    name: "caras-leaderboard___pt"
  }, {
    path: "/pt/comentarios",
    component: _24a661e7,
    name: "comments___pt"
  }, {
    path: "/pt/descobrir",
    component: _3a5ef95c,
    name: "discover___pt"
  }, {
    path: "/pt/diretorio",
    component: _1258498d,
    name: "directory___pt"
  }, {
    path: "/pt/embaixador",
    component: _49ff2526,
    name: "caras-ambassador___pt"
  }, {
    path: "/pt/esquecida-senha",
    component: _4eada6e4,
    name: "password-request___pt"
  }, {
    path: "/pt/favorites",
    component: _60709974,
    name: "favorites___pt"
  }, {
    path: "/pt/fotos",
    component: _733982b0,
    name: "pictures___pt"
  }, {
    path: "/pt/gerar-nova-senha",
    component: _57dc7b86,
    name: "password-generate___pt"
  }, {
    path: "/pt/home",
    component: _66e41e5c,
    name: "home___pt"
  }, {
    path: "/pt/legais",
    component: _0df0b08d,
    name: "legals___pt"
  }, {
    path: "/pt/login",
    component: _39d5dff4,
    name: "login___pt"
  }, {
    path: "/pt/lugares",
    component: _48ee0a02,
    name: "places___pt"
  }, {
    path: "/pt/maintenance",
    component: _30851220,
    name: "maintenance___pt"
  }, {
    path: "/pt/mapa",
    component: _419971b9,
    name: "map___pt",
    children: [{
      path: "/pt/mapa/:slug",
      component: _51112f98,
      name: "map-slug___pt"
    }]
  }, {
    path: "/pt/membro",
    component: _cb313366,
    name: "member___pt"
  }, {
    path: "/pt/newsletter",
    component: _67639928,
    name: "newsletter___pt"
  }, {
    path: "/pt/painel-de-controle",
    component: _43e95e18,
    name: "dashboard___pt"
  }, {
    path: "/pt/parceiros",
    component: _14925f68,
    name: "partner___pt"
  }, {
    path: "/pt/premio",
    component: _ecf38a98,
    name: "premium___pt"
  }, {
    path: "/pt/pressa",
    component: _1354c7e0,
    name: "press___pt"
  }, {
    path: "/pt/pro",
    component: _057f22ec,
    name: "pro___pt",
    children: [{
      path: "/pt/pro/:slug/activity",
      component: _28f38e6e,
      name: "pro-slug-activity___pt"
    }, {
      path: "/pt/pro/:slug/poi",
      component: _98804000,
      name: "pro-slug-poi___pt"
    }]
  }, {
    path: "/pt/registrar",
    component: _e33679d4,
    name: "register___pt"
  }, {
    path: "/pt/substitute",
    component: _85340a66,
    name: "substitute___pt"
  }, {
    path: "/pt/teamlist",
    component: _301464ee,
    name: "teamlist___pt"
  }, {
    path: "/pt/test",
    component: _b5a3a1f6,
    name: "test___pt"
  }, {
    path: "/pt/trombinoscopio",
    component: _ea05d178,
    name: "caras-trombinoscope___pt"
  }, {
    path: "/pt/viagens",
    component: _d18fc6da,
    name: "trips-home___pt"
  }, {
    path: "/voyages/categories",
    component: _6edd1701,
    name: "trips-themes___fr"
  }, {
    path: "/voyages/recommandations",
    component: _6f39c151,
    name: "trips-top___fr"
  }, {
    path: "/de/activities/karte",
    component: _2b6b40ed,
    name: "activities-map___de",
    children: [{
      path: "/de/activities/karte/:slug",
      component: _49360068,
      name: "activities-map-slug___de"
    }]
  }, {
    path: "/de/aktivitaeten/neu",
    component: _67878331,
    name: "activities-new___de"
  }, {
    path: "/de/area/willkommen",
    component: _7a1d6290,
    name: "poi-new___de"
  }, {
    path: "/de/reise/kategorien",
    component: _6edd1701,
    name: "trips-themes___de"
  }, {
    path: "/de/reise/oben",
    component: _6f39c151,
    name: "trips-top___de"
  }, {
    path: "/de/werbetreibende/medien-kit",
    component: _cee24614,
    name: "mediakit-contact___de"
  }, {
    path: "/en/activities/map",
    component: _2b6b40ed,
    name: "activities-map___en",
    children: [{
      path: "/en/activities/map/:slug",
      component: _49360068,
      name: "activities-map-slug___en"
    }]
  }, {
    path: "/en/activities/new",
    component: _67878331,
    name: "activities-new___en"
  }, {
    path: "/en/area/add",
    component: _7a1d6290,
    name: "poi-new___en"
  }, {
    path: "/en/mediakit/media-kit",
    component: _cee24614,
    name: "mediakit-contact___en"
  }, {
    path: "/en/trips/categories",
    component: _6edd1701,
    name: "trips-themes___en"
  }, {
    path: "/en/trips/top",
    component: _6f39c151,
    name: "trips-top___en"
  }, {
    path: "/es/actividades/mapa",
    component: _2b6b40ed,
    name: "activities-map___es",
    children: [{
      path: "/es/actividades/mapa/:slug",
      component: _49360068,
      name: "activities-map-slug___es"
    }]
  }, {
    path: "/es/actividades/nuevo",
    component: _67878331,
    name: "activities-new___es"
  }, {
    path: "/es/anunciantes/kit-de-medios",
    component: _cee24614,
    name: "mediakit-contact___es"
  }, {
    path: "/es/area/anadir",
    component: _7a1d6290,
    name: "poi-new___es"
  }, {
    path: "/es/viajes/arriba",
    component: _6f39c151,
    name: "trips-top___es"
  }, {
    path: "/es/viajes/categorias",
    component: _6edd1701,
    name: "trips-themes___es"
  }, {
    path: "/it/area/aggiungere",
    component: _7a1d6290,
    name: "poi-new___it"
  }, {
    path: "/it/attivita/mappa",
    component: _2b6b40ed,
    name: "activities-map___it",
    children: [{
      path: "/it/attivita/mappa/:slug",
      component: _49360068,
      name: "activities-map-slug___it"
    }]
  }, {
    path: "/it/attivita/nuovo",
    component: _67878331,
    name: "activities-new___it"
  }, {
    path: "/it/inserzionisti/kit-media",
    component: _cee24614,
    name: "mediakit-contact___it"
  }, {
    path: "/it/viaggi/categorie",
    component: _6edd1701,
    name: "trips-themes___it"
  }, {
    path: "/it/viaggi/cima",
    component: _6f39c151,
    name: "trips-top___it"
  }, {
    path: "/nl/activiteiten/map",
    component: _2b6b40ed,
    name: "activities-map___nl",
    children: [{
      path: "/nl/activiteiten/map/:slug",
      component: _49360068,
      name: "activities-map-slug___nl"
    }]
  }, {
    path: "/nl/activiteiten/nieuw",
    component: _67878331,
    name: "activities-new___nl"
  }, {
    path: "/nl/advertenties/media-kit",
    component: _cee24614,
    name: "mediakit-contact___nl"
  }, {
    path: "/nl/gebied/toevoegen",
    component: _7a1d6290,
    name: "poi-new___nl"
  }, {
    path: "/nl/reizen/boven",
    component: _6f39c151,
    name: "trips-top___nl"
  }, {
    path: "/nl/reizen/categorieen",
    component: _6edd1701,
    name: "trips-themes___nl"
  }, {
    path: "/pt/anunciantes/kit-de-midia",
    component: _cee24614,
    name: "mediakit-contact___pt"
  }, {
    path: "/pt/area/adcionar",
    component: _7a1d6290,
    name: "poi-new___pt"
  }, {
    path: "/pt/atividades/mapa",
    component: _2b6b40ed,
    name: "activities-map___pt",
    children: [{
      path: "/pt/atividades/mapa/:slug",
      component: _49360068,
      name: "activities-map-slug___pt"
    }]
  }, {
    path: "/pt/atividades/novo",
    component: _67878331,
    name: "activities-new___pt"
  }, {
    path: "/pt/viagens/categorias",
    component: _6edd1701,
    name: "trips-themes___pt"
  }, {
    path: "/pt/viagens/topo",
    component: _6f39c151,
    name: "trips-top___pt"
  }, {
    path: "/de/reise/kategorien/:category",
    component: _db8808e8,
    name: "trips-themes-category___de"
  }, {
    path: "/de/reise/lander/:country",
    component: _d9112c0a,
    name: "trips-themes-country___de"
  }, {
    path: "/en/trips/categories/:category",
    component: _db8808e8,
    name: "trips-themes-category___en"
  }, {
    path: "/en/trips/countries/:country",
    component: _d9112c0a,
    name: "trips-themes-country___en"
  }, {
    path: "/es/viajes/categorias/:category",
    component: _db8808e8,
    name: "trips-themes-category___es"
  }, {
    path: "/es/viajes/paises/:country",
    component: _d9112c0a,
    name: "trips-themes-country___es"
  }, {
    path: "/it/viaggi/categorie/:category",
    component: _db8808e8,
    name: "trips-themes-category___it"
  }, {
    path: "/it/viaggi/paesi/:country",
    component: _d9112c0a,
    name: "trips-themes-country___it"
  }, {
    path: "/nl/reizen/categorieen/:category",
    component: _db8808e8,
    name: "trips-themes-category___nl"
  }, {
    path: "/nl/reizen/landen/:country",
    component: _d9112c0a,
    name: "trips-themes-country___nl"
  }, {
    path: "/pt/viagens/categorias/:category",
    component: _db8808e8,
    name: "trips-themes-category___pt"
  }, {
    path: "/pt/viagens/paises/:country",
    component: _d9112c0a,
    name: "trips-themes-country___pt"
  }, {
    path: "/de/reise/kategorien/:category/lander/:country",
    component: _6709e8ee,
    name: "trips-themes-country-category___de"
  }, {
    path: "/en/trips/categories/:category/countries/:country",
    component: _6709e8ee,
    name: "trips-themes-country-category___en"
  }, {
    path: "/es/viajes/categorias/:category/paises/:country",
    component: _6709e8ee,
    name: "trips-themes-country-category___es"
  }, {
    path: "/it/viaggi/categorie/:category/paesi/:country",
    component: _6709e8ee,
    name: "trips-themes-country-category___it"
  }, {
    path: "/nl/reizen/categorieen/:category/landen/:country",
    component: _6709e8ee,
    name: "trips-themes-country-category___nl"
  }, {
    path: "/pt/viagens/categorias/:category/paises/:country",
    component: _6709e8ee,
    name: "trips-themes-country-category___pt"
  }, {
    path: "/de/meine-reise/:uuid",
    component: _da6174f4,
    name: "mytrips-uuid-detail___de"
  }, {
    path: "/de/partners/:slug",
    component: _59ada6ba,
    name: "partner-slug___de"
  }, {
    path: "/de/profil/:uuid",
    component: _3fc3ec94,
    name: "profile-uuid___de"
  }, {
    path: "/de/reise/:slug",
    component: _0cd97f0e,
    name: "trips-details-slug___de"
  }, {
    path: "/de/reset/:token",
    component: _dd359e6a,
    name: "password-reset-token___de"
  }, {
    path: "/de/send/:slug",
    component: _d8fa6814,
    name: "send-slug___de"
  }, {
    path: "/de/verzeichnis/:country",
    component: _cac35622,
    name: "directory-country___de"
  }, {
    path: "/en/directory/:country",
    component: _cac35622,
    name: "directory-country___en"
  }, {
    path: "/en/my-trips/:uuid",
    component: _da6174f4,
    name: "mytrips-uuid-detail___en"
  }, {
    path: "/en/partners/:slug",
    component: _59ada6ba,
    name: "partner-slug___en"
  }, {
    path: "/en/profile/:uuid",
    component: _3fc3ec94,
    name: "profile-uuid___en"
  }, {
    path: "/en/reset/:token",
    component: _dd359e6a,
    name: "password-reset-token___en"
  }, {
    path: "/en/send/:slug",
    component: _d8fa6814,
    name: "send-slug___en"
  }, {
    path: "/en/trips/:slug",
    component: _0cd97f0e,
    name: "trips-details-slug___en"
  }, {
    path: "/es/directorio/:country",
    component: _cac35622,
    name: "directory-country___es"
  }, {
    path: "/es/mis-viajes/:uuid",
    component: _da6174f4,
    name: "mytrips-uuid-detail___es"
  }, {
    path: "/es/perfil/:uuid",
    component: _3fc3ec94,
    name: "profile-uuid___es"
  }, {
    path: "/es/reset/:token",
    component: _dd359e6a,
    name: "password-reset-token___es"
  }, {
    path: "/es/send/:slug",
    component: _d8fa6814,
    name: "send-slug___es"
  }, {
    path: "/es/socios/:slug",
    component: _59ada6ba,
    name: "partner-slug___es"
  }, {
    path: "/es/viajes/:slug",
    component: _0cd97f0e,
    name: "trips-details-slug___es"
  }, {
    path: "/it/elenco/:country",
    component: _cac35622,
    name: "directory-country___it"
  }, {
    path: "/it/i-miei-viaggi/:uuid",
    component: _da6174f4,
    name: "mytrips-uuid-detail___it"
  }, {
    path: "/it/partner/:slug",
    component: _59ada6ba,
    name: "partner-slug___it"
  }, {
    path: "/it/profilo/:uuid",
    component: _3fc3ec94,
    name: "profile-uuid___it"
  }, {
    path: "/it/reset/:token",
    component: _dd359e6a,
    name: "password-reset-token___it"
  }, {
    path: "/it/send/:slug",
    component: _d8fa6814,
    name: "send-slug___it"
  }, {
    path: "/it/viaggi/:slug",
    component: _0cd97f0e,
    name: "trips-details-slug___it"
  }, {
    path: "/nl/directory/:country",
    component: _cac35622,
    name: "directory-country___nl"
  }, {
    path: "/nl/mijn-reizen/:uuid",
    component: _da6174f4,
    name: "mytrips-uuid-detail___nl"
  }, {
    path: "/nl/partners/:slug",
    component: _59ada6ba,
    name: "partner-slug___nl"
  }, {
    path: "/nl/profiel/:uuid",
    component: _3fc3ec94,
    name: "profile-uuid___nl"
  }, {
    path: "/nl/reizen/:slug",
    component: _0cd97f0e,
    name: "trips-details-slug___nl"
  }, {
    path: "/nl/reset/:token",
    component: _dd359e6a,
    name: "password-reset-token___nl"
  }, {
    path: "/nl/send/:slug",
    component: _d8fa6814,
    name: "send-slug___nl"
  }, {
    path: "/pt/as-minhas-viagens/:uuid",
    component: _da6174f4,
    name: "mytrips-uuid-detail___pt"
  }, {
    path: "/pt/diretorio/:country",
    component: _cac35622,
    name: "directory-country___pt"
  }, {
    path: "/pt/parceiros/:slug",
    component: _59ada6ba,
    name: "partner-slug___pt"
  }, {
    path: "/pt/perfil/:uuid",
    component: _3fc3ec94,
    name: "profile-uuid___pt"
  }, {
    path: "/pt/reset/:token",
    component: _dd359e6a,
    name: "password-reset-token___pt"
  }, {
    path: "/pt/send/:slug",
    component: _d8fa6814,
    name: "send-slug___pt"
  }, {
    path: "/pt/viagens/:slug",
    component: _0cd97f0e,
    name: "trips-details-slug___pt"
  }, {
    path: "/voyages/categories/:category",
    component: _db8808e8,
    name: "trips-themes-category___fr"
  }, {
    path: "/voyages/pays/:country",
    component: _d9112c0a,
    name: "trips-themes-country___fr"
  }, {
    path: "/de/aktivitaeten/:uuid/bearbeiten",
    component: _17e13c5e,
    name: "activities-uuid-edit___de"
  }, {
    path: "/de/aktivitaeten/:uuid/details",
    component: _145af99e,
    name: "activities-uuid-details___de"
  }, {
    path: "/de/aktivitaeten/:uuid/pro",
    component: _35790e89,
    name: "activities-uuid-pro___de"
  }, {
    path: "/de/area/:uuid/bearbeiten",
    component: _1aabbaf7,
    name: "poi-uuid-edit___de"
  }, {
    path: "/de/armaturenbrett/:uuid/details",
    component: _009baa6f,
    name: "dashboard-uuid-details___de"
  }, {
    path: "/de/bereich/:uuid/sperren",
    component: _2cfc9998,
    name: "poi-uuid-lock___de"
  }, {
    path: "/de/profil/:uuid/bearbeiten",
    component: _39be5438,
    name: "profile-uuid-edit___de"
  }, {
    path: "/de/profil/:uuid/delete",
    component: _1002344e,
    name: "profile-uuid-delete___de"
  }, {
    path: "/en/activities/:uuid/details",
    component: _145af99e,
    name: "activities-uuid-details___en"
  }, {
    path: "/en/activities/:uuid/edit",
    component: _17e13c5e,
    name: "activities-uuid-edit___en"
  }, {
    path: "/en/activities/:uuid/pro",
    component: _35790e89,
    name: "activities-uuid-pro___en"
  }, {
    path: "/en/area/:uuid/edit",
    component: _1aabbaf7,
    name: "poi-uuid-edit___en"
  }, {
    path: "/en/area/:uuid/lock",
    component: _2cfc9998,
    name: "poi-uuid-lock___en"
  }, {
    path: "/en/dashboard/:uuid/details",
    component: _009baa6f,
    name: "dashboard-uuid-details___en"
  }, {
    path: "/en/profile/:uuid/delete",
    component: _1002344e,
    name: "profile-uuid-delete___en"
  }, {
    path: "/en/profile/:uuid/edit",
    component: _39be5438,
    name: "profile-uuid-edit___en"
  }, {
    path: "/es/actividades/:uuid/detalles",
    component: _145af99e,
    name: "activities-uuid-details___es"
  }, {
    path: "/es/actividades/:uuid/editar",
    component: _17e13c5e,
    name: "activities-uuid-edit___es"
  }, {
    path: "/es/actividades/:uuid/pro",
    component: _35790e89,
    name: "activities-uuid-pro___es"
  }, {
    path: "/es/area/:uuid/bloquear",
    component: _2cfc9998,
    name: "poi-uuid-lock___es"
  }, {
    path: "/es/area/:uuid/editar",
    component: _1aabbaf7,
    name: "poi-uuid-edit___es"
  }, {
    path: "/es/perfil/:uuid/delete",
    component: _1002344e,
    name: "profile-uuid-delete___es"
  }, {
    path: "/es/perfil/:uuid/editar",
    component: _39be5438,
    name: "profile-uuid-edit___es"
  }, {
    path: "/es/tablero-de-control/:uuid/detalles",
    component: _009baa6f,
    name: "dashboard-uuid-details___es"
  }, {
    path: "/it/area/:uuid/modificare",
    component: _1aabbaf7,
    name: "poi-uuid-edit___it"
  }, {
    path: "/it/attivita/:uuid/dettagli",
    component: _145af99e,
    name: "activities-uuid-details___it"
  }, {
    path: "/it/attivita/:uuid/modifica",
    component: _17e13c5e,
    name: "activities-uuid-edit___it"
  }, {
    path: "/it/attivita/:uuid/pro",
    component: _35790e89,
    name: "activities-uuid-pro___it"
  }, {
    path: "/it/cruscotto/:uuid/dettagli",
    component: _009baa6f,
    name: "dashboard-uuid-details___it"
  }, {
    path: "/it/profilo/:uuid/delete",
    component: _1002344e,
    name: "profile-uuid-delete___it"
  }, {
    path: "/it/profilo/:uuid/modificare",
    component: _39be5438,
    name: "profile-uuid-edit___it"
  }, {
    path: "/it/zona/:uuid/bloccare",
    component: _2cfc9998,
    name: "poi-uuid-lock___it"
  }, {
    path: "/nl/activiteiten/:uuid/bewerken",
    component: _17e13c5e,
    name: "activities-uuid-edit___nl"
  }, {
    path: "/nl/activiteiten/:uuid/details",
    component: _145af99e,
    name: "activities-uuid-details___nl"
  }, {
    path: "/nl/activiteiten/:uuid/pro",
    component: _35790e89,
    name: "activities-uuid-pro___nl"
  }, {
    path: "/nl/dashboard/:uuid/details",
    component: _009baa6f,
    name: "dashboard-uuid-details___nl"
  }, {
    path: "/nl/gebied/:uuid/blokkeren",
    component: _2cfc9998,
    name: "poi-uuid-lock___nl"
  }, {
    path: "/nl/gebied/:uuid/edit",
    component: _1aabbaf7,
    name: "poi-uuid-edit___nl"
  }, {
    path: "/nl/profiel/:uuid/bewerken",
    component: _39be5438,
    name: "profile-uuid-edit___nl"
  }, {
    path: "/nl/profiel/:uuid/delete",
    component: _1002344e,
    name: "profile-uuid-delete___nl"
  }, {
    path: "/pt/area/:uuid/bloquear",
    component: _2cfc9998,
    name: "poi-uuid-lock___pt"
  }, {
    path: "/pt/area/:uuid/editar",
    component: _1aabbaf7,
    name: "poi-uuid-edit___pt"
  }, {
    path: "/pt/atividades/:uuid/detalhes",
    component: _145af99e,
    name: "activities-uuid-details___pt"
  }, {
    path: "/pt/atividades/:uuid/editar",
    component: _17e13c5e,
    name: "activities-uuid-edit___pt"
  }, {
    path: "/pt/atividades/:uuid/pro",
    component: _35790e89,
    name: "activities-uuid-pro___pt"
  }, {
    path: "/pt/painel-de-controle/:uuid/detalhes",
    component: _009baa6f,
    name: "dashboard-uuid-details___pt"
  }, {
    path: "/pt/perfil/:uuid/delete",
    component: _1002344e,
    name: "profile-uuid-delete___pt"
  }, {
    path: "/pt/perfil/:uuid/editar",
    component: _39be5438,
    name: "profile-uuid-edit___pt"
  }, {
    path: "/voyages/categories/:category/pays/:country",
    component: _6709e8ee,
    name: "trips-themes-country-category___fr"
  }, {
    path: "/de/verzeichnis/:country/:department",
    component: _55cc59d1,
    name: "directory-country-department___de"
  }, {
    path: "/en/directory/:country/:department",
    component: _55cc59d1,
    name: "directory-country-department___en"
  }, {
    path: "/es/directorio/:country/:department",
    component: _55cc59d1,
    name: "directory-country-department___es"
  }, {
    path: "/it/elenco/:country/:department",
    component: _55cc59d1,
    name: "directory-country-department___it"
  }, {
    path: "/nl/directory/:country/:department",
    component: _55cc59d1,
    name: "directory-country-department___nl"
  }, {
    path: "/pt/diretorio/:country/:department",
    component: _55cc59d1,
    name: "directory-country-department___pt"
  }, {
    path: "/de/verzeichnis/:country/:department/:type",
    component: _879d1bca,
    name: "directory-country-department-type___de"
  }, {
    path: "/en/directory/:country/:department/:type",
    component: _879d1bca,
    name: "directory-country-department-type___en"
  }, {
    path: "/es/directorio/:country/:department/:type",
    component: _879d1bca,
    name: "directory-country-department-type___es"
  }, {
    path: "/it/elenco/:country/:department/:type",
    component: _879d1bca,
    name: "directory-country-department-type___it"
  }, {
    path: "/nl/directory/:country/:department/:type",
    component: _879d1bca,
    name: "directory-country-department-type___nl"
  }, {
    path: "/pt/diretorio/:country/:department/:type",
    component: _879d1bca,
    name: "directory-country-department-type___pt"
  }, {
    path: "/annuaire/:country",
    component: _cac35622,
    name: "directory-country___fr"
  }, {
    path: "/envoyer/:slug",
    component: _d8fa6814,
    name: "send-slug___fr"
  }, {
    path: "/mes-voyages/:uuid",
    component: _da6174f4,
    name: "mytrips-uuid-detail___fr"
  }, {
    path: "/partenaires/:slug",
    component: _59ada6ba,
    name: "partner-slug___fr"
  }, {
    path: "/profil/:uuid",
    component: _3fc3ec94,
    name: "profile-uuid___fr"
  }, {
    path: "/reset/:token",
    component: _dd359e6a,
    name: "password-reset-token___fr"
  }, {
    path: "/voyages/:slug",
    component: _0cd97f0e,
    name: "trips-details-slug___fr"
  }, {
    path: "/activites/:uuid/details",
    component: _145af99e,
    name: "activities-uuid-details___fr"
  }, {
    path: "/activites/:uuid/modifier",
    component: _17e13c5e,
    name: "activities-uuid-edit___fr"
  }, {
    path: "/activites/:uuid/pro",
    component: _35790e89,
    name: "activities-uuid-pro___fr"
  }, {
    path: "/aire/:uuid/bloquer",
    component: _2cfc9998,
    name: "poi-uuid-lock___fr"
  }, {
    path: "/aire/:uuid/editer",
    component: _1aabbaf7,
    name: "poi-uuid-edit___fr"
  }, {
    path: "/profil/:uuid/delete",
    component: _1002344e,
    name: "profile-uuid-delete___fr"
  }, {
    path: "/profil/:uuid/editer",
    component: _39be5438,
    name: "profile-uuid-edit___fr"
  }, {
    path: "/tableau-de-bord/:uuid/details",
    component: _009baa6f,
    name: "dashboard-uuid-details___fr"
  }, {
    path: "/annuaire/:country/:department",
    component: _55cc59d1,
    name: "directory-country-department___fr"
  }, {
    path: "/annuaire/:country/:department/:type",
    component: _879d1bca,
    name: "directory-country-department-type___fr"
  }, {
    path: "/",
    component: _196d6cef,
    name: "index___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
